import Preact from "preact";

export default function Social() {
  return (
    <div class="social">
      <a
        target="_blank"
        class="ss-icon ss-social"
        href="https://www.facebook.com/pages/Boyd-Aluminum/125770204144145"
      >
        facebook
      </a>
      <a
        target="_blank"
        class="ss-icon ss-social"
        href="https://twitter.com/Boyd_LetsShine"
      >
        twitter
      </a>
      <a
        target="_blank"
        class="ss-icon ss-social"
        href="http://www.linkedin.com/company/boyd-aluminum"
      >
        linkedin
      </a>
      <a
        target="_blank"
        class="ss-icon ss-social"
        href="http://www.youtube.com/user/Boydletsshine"
      >
        youtube
      </a>
    </div>

  );

}
